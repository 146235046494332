@font-face {
    font-family: 'Celtic Hand';
    src: local('Celtic Hand'), url(./fonts/CelticHand.ttf) format('truetype');
}

.center {
    margin: auto;
    width: 50%;
    height: 50%;
    padding: 10px;
}

.gaelic-text {
    font-family: 'Celtic Hand';
}

.time-prompt {
    font-size: 18px;
}

.time-prefix {
    font-size: 24px;
}

.time-hour {
    font-size: 64px;
}

.number {
    color: black;
    font-weight: lighter;
}
.non-number {
    color: lightslategray;
    font-weight: lighter;
}

#clock-container {
    width: 200px;
    height: 200px;
}

#clock-face {
	stroke-width: 2px;
    stroke: lightslategray; 
    fill: white;
}

#hour-marker {
	stroke-width: 2px;
    stroke: lightslategray; 
    fill: white;
}

#hour-hand {
	stroke-width: 3px;
    stroke: black;
}
#minute-hand {
	stroke-width: 2px;
    stroke: black;
}

#second-hand {
	stroke-width: 1px;
    fill: red;
    stroke: red;
}
#clock-center {
    fill: white;
    stroke: red;
}